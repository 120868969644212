<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="6"
                             lg="4">
                        <mdb-input label="分類名稱"
                                   v-model="search.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="6"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="search.page=1;data.nowPage=1;searchdata()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                        <mdb-btn color="secondary"
                                 size="sm"
                                 class="mt-3"
                                 v-show="search.name!=''"
                                 @click="search.name='';search.page=1;data.nowPage=1;searchdata()">
                            取消搜尋</mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <div class="mb-2">
                    <mdb-btn color="secondary"
                             @click="add_class()">
                        <i class="fa fa-plus mr-2"></i>
                        &nbsp;新增分類
                    </mdb-btn>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width:3em">#</th>
                            <th style="width:50%">分類名稱</th>
                            <th class="text-center"
                                style="width:5em">啟用</th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,sk) in product_classList"
                            :key="`class_${sk}`">
                            <td>{{sk+1}}</td>
                            <td @keypress.enter="save_class(s)">
                                <span v-show="!s.edit">{{s.name}}</span>
                                <mdb-input type="text"
                                           class="my-0"
                                           placeholder="請輸入分類名稱"
                                           v-show="s.edit"
                                           v-model="s.name" />
                            </td>
                            <td class="text-center">
                                <span v-show="!s.edit">
                                    <i class="fa"
                                       :class="s.active?'fa-check':'fa-times'"></i>
                                </span>
                                <mdb-switch offLabel=""
                                            onLabel=''
                                            :checked="s.active"
                                            v-show="s.edit"
                                            @getValue="val=>s.active=val" />
                            </td>
                            <td>
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="s.edit=true"
                                         v-show="!s.edit">
                                    <i class="fa fa-edit mr-2"></i>
                                    編輯
                                </mdb-btn>
                                <mdb-btn color="default"
                                         size="sm"
                                         @click="save_class(s)"
                                         v-show="s.edit">
                                    <i class="fa fa-save mr-2"></i>
                                    儲存
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4"
                                v-show="product_classList.length==0">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;getData();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbBtn,
  mdbSwitch,
} from "mdbvue";
import page from "../../components/page";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbSwitch,
    page,
  },
  data() {
    return {
      search: {
        name: "",
        page: 1,
      },
      data: {
        nowPage: 1,
        totalPage: 0,
      },
      product_classList: [],
      product_classEmpty: {},
    };
  },
  mounted() {
    console.clear();
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      vue.search[i] = vue.query[i] ? vue.query[i] : "";
    }
    vue.search.page = vue.search.page == "" ? 1 : vue.search.page;
    this.getData();
  },
  computed: {},
  methods: {
    // 搜尋資料
    searchdata() {
      let vue = this;
      let url = vue.$route.path + "?";
      for (let k in vue.search) {
        url += `${k}=${vue.search[k]}&`;
      }
      vue.$router.push(url);
      vue.getData();
    },
    getData() {
      let vue = this;
      let query = [
        // 取列表
        {
          name: "product_class",
          data: {
            name: {
              type: 6,
              value: vue.search.name == "" ? "%" : `%${vue.search.name}%`,
            },
          },
          sort: {
            active: 1,
            name: 0,
          },
          pg: vue.search.page == "" ? 1 : vue.search.page,
        },
        // 取數量
        {
          name: "product_class",
          data: {
            name: {
              type: 6,
              value: vue.search.name == "" ? "%" : `%${vue.search.name}%`,
            },
          },
          num: "1",
        },
        // 取空陣列
        {
          name: "product_class",
        },
      ];

      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${encodeURI(JSON.stringify(query))}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            // console.info(res.data);
            // class
            vue.product_classList = res.data.product_classList.map((item) => {
              item.edit = false;
              item.ajaxing = false;
              item.active = item.active == "1";
              return item;
            });

            res.data.product_classEmpty.edit = true;
            res.data.product_classEmpty.ajaxing = false;
            res.data.product_classEmpty.active = true;
            res.data.product_classEmpty.id = "";
            vue.product_classEmpty = res.data.product_classEmpty;
            vue.data.totalPage = Math.ceil(res.data.product_classNumber / 10);
            vue.data.nowPage = vue.search.page;
          }
        });
    },
    add_class() {
      this.product_classList.unshift(
        Object.assign({}, this.product_classEmpty)
      );
    },
    save_class(s) {
      if (!s.ajaxing && s.edit) {
        let vue = this,
          msg = "";
        switch (true) {
          case !s.name || s.name == "":
            msg = "請填寫 分類名稱";
            break;
        }

        if (msg == "") {
          s.ajaxing = true;
          vue.$store
            .dispatch("post_form", {
              payload: {
                url: "data/product_class_save",
                data: { data: JSON.stringify(s) },
              },
            })
            .then((res) => {
              if (res.data.status == "ok") {
                s.id = res.data.product_class_id;
                s.edit = false;
                s.ajaxing = false;
              }
            });
        } else {
          vue.$swal.fire({
            icon: "error",
            title: msg,
            timer: 1500,
            showConfirmButton: false,
            toast: true,
          });
        }
      }
    },
  },
};
</script>